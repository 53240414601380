$blue: #2b446a;

$red: #ff2211;
$dark-red: #ee2211;

$white: #ffffff;
$lightest-grey: #f5f5f5;
$light-grey: #eeeeee;
$grey: #c6c6c6;
$dark-grey: #808080;
$grey-f0: #f0f0f0;
$grey-e5: #e5e5e5;
$grey-c6: #c6c6c6;
$grey-cc: #ccc;
$grey-c0: #c0c0c0;
$red-f21: #f21;
$darkest-grey: #222222;
